import axios from 'axios';
import {
    GET_PRODUCTS_BY_SUBCAT, LOADING_START, LOADING_END, ADD_PRODUCT_TOOLTIP, SET_ORDER_COMMENT, ADD_TO_CART,
    CLEAR_CART, ORDER_FAILED, ORDERED_SUCCESSFULLY, GET_ALL_PRODUCTS, LOADING_ORDER_START, LOADING_ORDER_END,
    ADD_TO_ORDERED, CLEAR_ORDER, CHANGE_SELECTED_CART_TAB, GET_ORDERS, BILL_ASKED, CLEAR_BILL_ASKED, LOADING_CART_START,
    LOADING_CART_END, ORDER_SPOT_FAILED, SET_PRODUCT_PAGE_ID, REMOVE_PRODUCT_PAGE_ID,
    OPEN_COMMENT_MODAL, COMMENT_MODAL_TITLE, COMMENT_VARIANT_ID, PAYMENT_TYPE_MODAL_OPENED, SET_DISABLED_MAKE_ORDER_BUTTON,
    LIST_INGREDIENTS, PLACE_IMAGE_UPLOADED, SET_UPSELLING_PRODUCTS_MODAL_OPENED
} from './types';
import { API_URL, DEFAULT_PRODUCT_IMAGE, BILL_WANTED_EXPIRE_TIME_MINUTES } from '../../constants'
import { openScanModal, setCurrOpenedModal, closeScanModal, setOrderModalState } from './../../qr-scan/actions/scan.actions';
// import _ from 'lodash';
import Utils from '../../utils/Utils';
import Auth from '../../utils/Auth';
import history from './../../utils/history';
import { isEqual, sortBy } from 'lodash';
import ReactPixel from 'react-facebook-pixel';
import { addErrorMessages, addSuccessMessage } from './../../messages/messages.actions';
import { setErrorAndReloadModalMessage } from './../../menu/menu.actions';
import { updateRemoteCart, getCartIdLocal, removeCartIdLocal } from './../../cart/actions/cart.actions';
import { initiateEpayOrder, initiateEpayOrderOneTouch, setProcessingPaymentModalOpened } from './../../payments/epay/epay.actions'
import { setLoginModalOpened } from './../../users/actions/users.actions'
import { setCartModalOpenedState } from './../../menu/menu.actions'
import { getRegisteredUserDiscounts } from './../../registered_user_discounts/actions/registered_user_discounts.actions'

import store from '../../store';
import { uniq } from 'lodash';

ReactPixel.init('2912758035505954');


export const getProductIngredients = () => dispatch => {
    axios
        .get(API_URL + 'ingredients')
        .then(res => {
            dispatch({
                type: LIST_INGREDIENTS,
                payload: res.data
            });
        })
        .catch(error => {
            throw (error);
        });
}


export const getAllProductsIfOnePlace = () => dispatch => {
    // if (showLoading) {
    dispatch({ type: LOADING_START });
    // }

    let url = 'productsOnePlace';

    axios.get(API_URL + url)
        .then(res => {
            dispatch({
                type: GET_ALL_PRODUCTS,
                payload: res.data
            });
            // if (showLoading) {
            dispatch({ type: LOADING_END });
            // }
        });
}



export const getProductsBySubcat = (subcat_id) => dispatch => {
    dispatch({ type: LOADING_START });

    axios
        .get(API_URL + 'subcategories/' + subcat_id)
        .then(res => {
            dispatch({
                type: GET_PRODUCTS_BY_SUBCAT,
                payload: res.data
            });
            dispatch({ type: LOADING_END });
        });
}

export const getProductsToOrder = () => dispatch => {
    dispatch({
        type: ADD_TO_CART,
        payload: getLocalCart()
    });
}

export const addProductTooltip = (id, name) => dispatch => {
    dispatch({
        type: ADD_PRODUCT_TOOLTIP,
        payload: { id: id, name: name }
    })
}

export const setOrderComment = (comments) => dispatch => {
    dispatch({
        type: SET_ORDER_COMMENT,
        payload: comments
    })
}

export const upsellProducts = (product) => dispatch => {
    if (product.related_products.length > 0) {
        dispatch(setUpsellingProductsModalOpened(true, product));
    }
}

export const setUpsellingProductsModalOpened = (state, product) => dispatch => {
    dispatch({
        type: SET_UPSELLING_PRODUCTS_MODAL_OPENED,
        payload: {
            modalOpened: state,
            productToUpsell: product
        }
    })
}

export const addToCart = (product_id, product_variant_id, product, selectedIngredients, category_id = null) => dispatch => {
    // console.log("ADD TO CART");
    let catId = category_id || product.catHierarchyIds[0];
    addToCartLocal(product_id, product_variant_id, false, product, selectedIngredients, catId);

    // dispatch(updateRemoteCart());

    dispatch(upsellProducts(product));

    dispatch({
        type: ADD_TO_CART,
        payload: getLocalCart()
    })
}

export const removeProductFromCart = (product = null, prodIds = []) => dispatch => {
    // prodIds - MUST BE ARRAY | if prodIds passed - ingredients does NOT matter
    var to_order = window.localStorage.getItem('to_order');//setItem('token', token)
    // console.log("REMOVE TO CART");

    if (!to_order) {
        to_order = {};
    } else {
        to_order = JSON.parse(to_order);
    }

    if (!to_order) {
        return null;
    }

    if (product == null && Object.values(prodIds).length > 0) {
        Object.values(to_order).map(pvs =>
            pvs.filter(p => prodIds.includes(p.product_id)).map(pp => {
                if (to_order[pp.product_variant_id]) {
                    delete to_order[pp.product_variant_id];
                }
            })
        )
    } else {

        let { product_variant_id, ingredients } = product;

        if (product_variant_id) {
            if (to_order && to_order[product_variant_id] && to_order[product_variant_id][getUpdatableIndex(product_variant_id, ingredients)]) {
                // console.log('to_order[product_variant_id][getUpdatableIndex(product_variant_id, ingredients)]', to_order[product_variant_id][getUpdatableIndex(product_variant_id, ingredients)])
                delete to_order[product_variant_id][getUpdatableIndex(product_variant_id, ingredients)];
                to_order[product_variant_id].splice(getUpdatableIndex(product_variant_id, ingredients), 1)
            }
        } else {
            // if (!product_variant_id && product.product_variants && product.product_variants[0] && product.product_variants[0].id) {
            //     product_variant_id = product.product_variants[0].id;
            // }

            if (product.product_variants && product.product_variants.length > 0) {

                product.product_variants.map(pv => {
                    if (pv.id && to_order[pv.id]) {
                        product_variant_id = pv.id;
                        if (to_order && to_order[product_variant_id] && to_order[product_variant_id][getUpdatableIndex(product_variant_id, ingredients)]) {
                            // console.log('to_order[product_variant_id][getUpdatableIndex(product_variant_id, ingredients)]', to_order[product_variant_id][getUpdatableIndex(product_variant_id, ingredients)])
                            delete to_order[product_variant_id][getUpdatableIndex(product_variant_id, ingredients)];
                            to_order[product_variant_id].splice(getUpdatableIndex(product_variant_id, ingredients), 1)
                        }
                    }
                })
            }
        }

    }

    // if (!product_variant_id && prodVariantId) {
    //     product_variant_id = prodVariantId;
    // }


    // if (to_order && to_order[product_variant_id] && to_order[product_variant_id][getUpdatableIndex(product_variant_id, ingredients)]) {
    //     delete to_order[product_variant_id][getUpdatableIndex(product_variant_id, ingredients)];
    //     to_order[product_variant_id].splice(getUpdatableIndex(product_variant_id, ingredients), 1)
    // }


    // delete to_order[product_variant_id];

    window.localStorage.setItem('to_order', JSON.stringify(to_order))

    // dispatch(updateRemoteCart());

    dispatch({
        type: ADD_TO_CART,
        payload: getLocalCart()
    })

}

export const updateProductQuantity = (product, quantity) => dispatch => {
    var to_order = window.localStorage.getItem('to_order');//setItem('token', token)

    // debugger
    if (!to_order) {
        to_order = {};
    } else {
        to_order = JSON.parse(to_order);
    }

    const { product_variant_id, ingredients } = product;

    if (to_order && to_order[product_variant_id] && to_order[product_variant_id][getUpdatableIndex(product_variant_id, ingredients)] && to_order[product_variant_id][getUpdatableIndex(product_variant_id, ingredients)]['quantity']) {
        to_order[product_variant_id][getUpdatableIndex(product_variant_id, ingredients)]['quantity'] = quantity;
    }

    // if (to_order[product_variant_id]['quantity']) {
    //     to_order[product_variant_id]['quantity'] = quantity;
    // }
    window.localStorage.setItem('to_order', JSON.stringify(to_order))

    // dispatch(updateRemoteCart());

    dispatch({
        type: ADD_TO_CART,
        payload: getLocalCart()
    })
}

// export const getCartProductQuantity = (selectedIngredients) => {
//     var to_order = window.localStorage.getItem('to_order');//setItem('token', token)
//     to_order = JSON.parse(to_order);

//     let cartProductQuantity = 0;
//     if (to_order && Object.values(to_order).length > 0) {
//         Object.values(to_order).map(o => {
//             if (o.findIndex(i => parseInt(i.ingredients.required) == parseInt(selectedIngredients.required) && (isEqual(sortBy(i.ingredients.addable), sortBy(selectedIngredients.addable)) && (isEqual(sortBy(i.ingredients.removable), sortBy(selectedIngredients.removable))))) > -1) {
//                 cartProductQuantity = o.quantity
//             }
//         })
//     }
//     return cartProductQuantity;

// } 

// const differentIngridients = (product_variant_id, selectedIngredients) => {
//     var to_order = window.localStorage.getItem('to_order');//setItem('token', token)
//     to_order = JSON.parse(to_order);


//     let equalIngridients = false;
//     if (to_order && Object.values(to_order).length > 0) {
//         Object.values(to_order).map(o => {
//             equalIngridients = equalIngridients || (o.findIndex(i => parseInt(i.ingredients.required) == parseInt(selectedIngredients.required) && (isEqual(sortBy(i.ingredients.addable), sortBy(selectedIngredients.addable)) && (isEqual(sortBy(i.ingredients.removable), sortBy(selectedIngredients.removable))))) > -1)
//         })
//     }
//     return !equalIngridients;

// }

const getUpdatableIndex = (product_variant_id, selectedIngredients) => {
    var to_order = window.localStorage.getItem('to_order');//setItem('token', token)
    to_order = JSON.parse(to_order);

    let updateIndex = -1;
    if (to_order && Object.values(to_order).length > 0) {
        Object.values(to_order).map(o => {

            // updateIndex = updateIndex == -1 ? (o.findIndex(i =>
            //     i.product_variant_id == product_variant_id

            //     // && ((!i.ingredients || Object.values(i.ingredients).length == 0 || (i.ingredients && i.ingredients.required && i.ingredients.required == 0)) ||
            //     //     (parseInt(i.ingredients.required) == parseInt(selectedIngredients.required)
            //     //         && (isEqual(sortBy(i.ingredients.addable), sortBy(selectedIngredients.addable)))
            //     //         && (isEqual(sortBy(i.ingredients.removable), sortBy(selectedIngredients.removable)))
            //     //     )
            //     // )
            // )) : updateIndex
            updateIndex = updateIndex == -1 ? (o.findIndex(i => i.product_variant_id == product_variant_id && i.ingredients && i.ingredients.hasOwnProperty("required") && parseInt(i.ingredients.required) == parseInt(selectedIngredients.required) && (isEqual(sortBy(i.ingredients.addable), sortBy(selectedIngredients.addable)) && (isEqual(sortBy(i.ingredients.removable), sortBy(selectedIngredients.removable)))))) : updateIndex

        })
    }

    return updateIndex;
}



// 
// static differentIngridients(product_variant_id, selectedIngredients) {
//     // console.log('product_variant_id', product_variant_id)
//     // console.log('selectedIngredients', selectedIngredients)
//     var to_order = window.localStorage.getItem('to_order');//setItem('token', token)
//     to_order = JSON.parse(to_order);

//     let differentIngridients = false;
//     if (to_order && Object.values(to_order).length > 0) {
//         // console.log('to_order', to_order)
//         Object.values(to_order).map(o => {
//             // console.log('real o', o)

//             // console.log('o.length', o.length)
//             // console.log('o.filter(op=>op.product_variant_id == product_variant_id', o.filter(op => op.product_variant_id == product_variant_id));

//             if (o.filter(op => op.product_variant_id == product_variant_id).length == 1) {
//                 // console.log('1 e')
//                 // console.log('o[0]', o[0])
//                 // console.log('o[0].ingredients', o[0].ingredients)
//                 // console.log('parseInt(o[0].product_variant_id) == parseInt(product_variant_id', parseInt(o[0].product_variant_id) == parseInt(product_variant_id));
//                 // console.log('parseInt(o[0].ingredients.required) == parseInt(selectedIngredients.required)', parseInt(o[0].ingredients.required) == parseInt(selectedIngredients.required))
//                 // console.log('isEqual(sortBy(o[0].ingredients.addable), sortBy(selectedIngredients.addable))', isEqual(sortBy(o[0].ingredients.addable), sortBy(selectedIngredients.addable)))
//                 // console.log('isEqual(sortBy(o[0].ingredients.removable), sortBy(selectedIngredients.removable))', isEqual(sortBy(o[0].ingredients.removable), sortBy(selectedIngredients.removable)))

//                 if (parseInt(o[0].product_variant_id) == parseInt(product_variant_id)
//                     &&

//                     (Object.values(o[0].ingredients).length == 0 ||
//                         (parseInt(o[0].ingredients.required) == parseInt(selectedIngredients.required)
//                             && (isEqual(sortBy(o[0].ingredients.addable), sortBy(selectedIngredients.addable)))
//                             && (isEqual(sortBy(o[0].ingredients.removable), sortBy(selectedIngredients.removable)))
//                         )
//                     )
//                 ) {

//                     differentIngridients = true;
//                     // console.log('imago 1')
//                 }

//             } else if (o.filter(op => op.product_variant_id == product_variant_id).length > 1) {
//                 // console.log('nee 1')
//                 Object.values(o).map(oo => {
//                     if (parseInt(oo.product_variant_id) == parseInt(product_variant_id)
//                         && parseInt(oo.ingredients.required) == parseInt(selectedIngredients.required)
//                         && (isEqual(sortBy(oo.ingredients.addable), sortBy(selectedIngredients.addable)))
//                         && (isEqual(sortBy(oo.ingredients.removable), sortBy(selectedIngredients.removable)))
//                     ) {

//                         differentIngridients = true;
//                         // console.log('imago 2')
//                     }
//                 })
//             }

//         })
//     }
//     // console.log('razlichen li e :', !differentIngridients)
//     return !differentIngridients;

// }
// 


export const addToCartLocal = (product_id, product_variant_id, quantity, product, selectedIngredients = {}, category_id = null) => {

    var to_order = window.localStorage.getItem('to_order');//setItem('token', token)
    // debugger
    if (!to_order) {
        to_order = {};
    } else {
        to_order = JSON.parse(to_order);
    }

    if (selectedIngredients.required) {
        selectedIngredients.required = selectedIngredients.required;
    }

    if (!to_order[product_variant_id] || (to_order[product_variant_id] && Utils.differentIngridients(product_variant_id, selectedIngredients))) {
        let selectedProdVariant = product['product_variants'].filter(pv => pv.id === product_variant_id)[0];
        // console.log('selectedProdVariant', selectedProdVariant)
        let toOrderProd = {};
        if (!to_order[product_variant_id]) {
            to_order[product_variant_id] = [];
        }

        toOrderProd['product_variant_id'] = product_variant_id;
        toOrderProd['product_id'] = product_id;
        toOrderProd['size'] = selectedProdVariant['size'];
        toOrderProd['price'] = selectedProdVariant['price'];
        toOrderProd['additional_price'] = selectedProdVariant['additional_price'];
        toOrderProd['name'] = product['name'];
        toOrderProd['image'] = product['product_images'] && product['product_images'][0] && product['product_images'][0].path ? Utils.getProductImagePath(product['product_images'][0].path, 1) : DEFAULT_PRODUCT_IMAGE;
        toOrderProd['date_added'] = new Date().getTime();
        toOrderProd['product_translations'] = product.translations || '';
        toOrderProd['variant_translations'] = selectedProdVariant.translations || '';
        toOrderProd['product_ingredients'] = product.product_ingredients || '';
        toOrderProd['ingredients'] = selectedIngredients || '';
        toOrderProd['quantity'] = 1;
        toOrderProd['catHierarchyId'] = category_id;
        toOrderProd['discount_percent'] = product.discount_percent;
        toOrderProd['discount_money'] = product.discount_money;
        to_order[product_variant_id].push(toOrderProd);

    } else {

        if (!quantity) {
            if (to_order[product_variant_id] && to_order[product_variant_id][getUpdatableIndex(product_variant_id, selectedIngredients)] && to_order[product_variant_id][getUpdatableIndex(product_variant_id, selectedIngredients)]['quantity']) {
                to_order[product_variant_id][getUpdatableIndex(product_variant_id, selectedIngredients)]['quantity'] += 1;
            }
        } else {
            to_order[product_variant_id][getUpdatableIndex(product_variant_id, selectedIngredients)]['quantity'] = quantity;
        }
    }


    window.localStorage.setItem('to_order', JSON.stringify(to_order))
}

export const getLocalCart = function () {
    var to_order = window.localStorage.getItem('to_order');
    if (!to_order) {
        to_order = [];
    } else {
        to_order = JSON.parse(to_order);
    }

    let sortedOrders = to_order;
    // sortedOrders = _.sortBy(sortedOrders, [function(o) { return o.date_added; }])
    sortedOrders = sortBy(sortedOrders, ['date_added'])
    return sortedOrders;

    // return to_order;
}

export const clearCartLocal = () => dispatch => {
    window.localStorage.removeItem('to_order');
    dispatch({ type: CLEAR_CART })// clear cart from state 

    removeCartIdLocal(); // clear cart uuid from cookies
}


const makeOrderCatcher = (error, msg = '') => dispatch => {
    if (error && error.response && error.response.data && error.response.data && error.response.data.payload) {

        let inactiveProductIngredientsIds = error.response.data.payload.inactiveProductIngredientsIds;
        let inactiveProductsIds = error.response.data.payload.inactiveProductsIds;
        let noProductsToInsert = error.response.data.payload.noProductsToInsert;

        if (inactiveProductIngredientsIds && Object.values(inactiveProductIngredientsIds).length > 0) {
            inactiveProductIngredientsIds = uniq(inactiveProductIngredientsIds)

            let allProducts = store.getState().products.all_products;

            let inactiveProductNames = '';
            (inactiveProductIngredientsIds).map((pId, k) => {
                inactiveProductNames = inactiveProductNames + ((inactiveProductIngredientsIds.length - 2 != k && k != 0) ? " и " : '') + "'" + (allProducts && allProducts[pId] && allProducts[pId].name || "") + "'" + (inactiveProductIngredientsIds.length - 2 > k ? ", " : '');
            })

            dispatch(removeProductFromCart(null, inactiveProductIngredientsIds))
            dispatch({
                type: ORDER_FAILED,
                payload: { message: `Продуктите: '${inactiveProductNames}' съдържат съставки, които вече не са налични. За да поръчате без тези продукти натиснете отново Поръчай или обновете страницата и добавете други съставки към продуктите.` }
            })
            dispatch(addErrorMessages(["Възникна грешка"]));
        } else if (inactiveProductsIds && Object.values(inactiveProductsIds).length > 0) {
            let allProducts = store.getState().products.all_products;

            let inactiveProductNames = '';
            (inactiveProductsIds).map((pId, k) => {
                inactiveProductNames = inactiveProductNames + ((inactiveProductsIds.length - 2 != k && k != 0) ? " и " : '') + "'" + (allProducts && allProducts[pId] && allProducts[pId].name || "") + "'" + (inactiveProductsIds.length - 2 > k ? ", " : '');
            })

            dispatch(removeProductFromCart(null, inactiveProductsIds))
            dispatch({
                type: ORDER_FAILED,
                payload: { message: `Продуктите: '${inactiveProductNames}' вече не са налични, за да поръчате без тях натиснете отново Поръчай или обновете страницата и добавете други продукти в количката.` }
            })
            dispatch(addErrorMessages(["Възникна грешка"]));
        }
        else if (error.response.data.payload.message && error.response.data.payload.message == "invalid_products_for_place") {
            dispatch(clearCartLocal());
            dispatch(setErrorAndReloadModalMessage("Опитвате да поръчате продукти от друг обект. Страницата ще бъде презаредена. Моля поръчайте отново. Извиняваме се за неудобството :)"))
        } else if (error.response.data.payload.message && error.response.data.payload.message == "orders_not_allowed") {
            dispatch(addErrorMessages(["В момента не приемаме поръчки. Моля опитайте по-късно."]));
        } else if (error.response.data.payload.message && noProductsToInsert) {
            dispatch(addErrorMessages(["Няма продукти в количката, моля добавете и след това опитайте да поръчате"]));
        } else if (error.response.data.payload.message && error.response.data.payload.message == "card_payment_requires_login") {
            dispatch(addErrorMessages(["За да платите онлайн трябва да сте влезли в акаунта си"]));
            dispatch(setLoginModalOpened(true));
        } else if (error.response.data.payload.message && error.response.data.payload.message == "min_order_price_for_radius_not_fulfilled") {
            let msg = error.response.data.payload.message_trans ? error.response.data.payload.message_trans : "Моля добавете още продукти в количката"
            dispatch(addErrorMessages([msg]));
        } else if (error.response.data.payload.message && error.response.data.payload.message == 'anon_user_banned') {
            let msg = error.response.data.payload.message_trans ? error.response.data.payload.message_trans : "Не може да пускате повече поръчки."
            dispatch(addErrorMessages([msg]));
        } else if (error.response.data.payload.message && error.response.data.payload.message == 'invalid_delivery_shape') {
            let msg = "Моля проверете адреса за доставка или го въведете отново"
            dispatch(addErrorMessages([msg]));
        } else {
            dispatch(addErrorMessages([msg ? msg : "Моля опитайте отново"]));
        }
    }
    else {
        dispatch({
            type: ORDER_FAILED,
            payload: { message: "Възникна грешка при изпращане на поръчката!" } //No translation needed
        })
        dispatch(addErrorMessages([msg ? msg : "Моля опитайте отново"]));
    }
}

export const makeOrder = (orderData, orderComment, orderType = 2, translations) => dispatch => {
    if (orderData && (orderData.payment_method == "epay")) {
        if (!Auth.getAuthUserToken()) {
            dispatch(addErrorMessages(["За да платите онлайн трябва да сте влезли в акаунта си"]));
            dispatch(setLoginModalOpened(true));
            return;
        }
    }

    dispatch({ type: LOADING_ORDER_START });
    window.localStorage.removeItem('bill_wanted');

    if (window.location.hostname !== 'localhost'
        && window.location.hostname !== 'test.tabl.bg'
        && window.location.hostname !== 'gabrovo-pizza.tabl.bg'
    ) {
        ReactPixel.trackSingleCustom('2912758035505954', "PurchaseTry", { name: 'Name: ' + orderData.name + ' Phone: ' + orderData.phone + ' OrderType: ' + orderType }); // For tracking custom events.
    }

    if (orderType == 1) {
        dispatch(sendRequestRestaurant(orderComment, translations));
    } else if (orderType == 2) {
        dispatch(sendRequestDelivery(orderData, orderComment));
    } else if (orderType == 3) {
        dispatch(sendRequestPickup(orderData, orderComment));
    } else if (orderType == 6) {
        dispatch(sendRequestRoom(orderData, orderComment));
    } else if (orderType == 7) {
        dispatch(sendRequestServedOnSpot(orderData, orderComment));
    }

}


// const sendSmsForNewOrder = (orderData, products, orderTypes, phoneNum) => {
//     const { name, phone, address, email, pickupDate, pickupTime } = orderData;

//     let pickupDateFormat = new Date(pickupDate + '.' + (new Date()).getFullYear());
//     pickupDateFormat = Auth.appendLeadingZeroes(pickupDateFormat.getDate()) + '.' + Auth.appendLeadingZeroes(parseInt(parseInt(pickupDateFormat.getMonth()) + 1));

//     let pickupTimeText = '';
//     if (orderTypes == 3) {
//         pickupTimeText = 'Shte%20vzeme%20v:%20' + pickupDateFormat + '%20' + pickupTime;
//     } else if (orderTypes == 2) {
//         pickupTimeText = 'Dostavi%20v:%20' + pickupDateFormat + '%20' + pickupTime;
//     }

//     let prods = '';
//     Object.values(products).map(p => {
//         prods += p.quantity + ' x ' + p.name + ' | ';
//     });

//     // DENIS PHONE axios.get(`https://platform.clickatell.com/messages/http/send?apiKey=q8ppKOM_Sy-gydxpYqKXvw==&to=359884014689&content=TabL.BG%20|%20Nova%20poruchka%20ot%20${name}:%20${prods}%20${pickupTimeText}`) //DENIS PHONE
//     axios.get(`https://platform.clickatell.com/messages/http/send?apiKey=q8ppKOM_Sy-gydxpYqKXvw==&to=${phoneNum}&content=TabL.BG%20|%20Nova%20poruchka%20ot%20${name}:%20${prods}%20${pickupTimeText}`)

// }

// const sendSmsForNewOrderDenis = (orderData, products, orderTypes) => {
//     const { name, phone, address, email, pickupDate, pickupTime } = orderData;

//     let pickupDateFormat = new Date(pickupDate + '.' + (new Date()).getFullYear());
//     pickupDateFormat = Auth.appendLeadingZeroes(pickupDateFormat.getDate()) + '.' + Auth.appendLeadingZeroes(parseInt(parseInt(pickupDateFormat.getMonth()) + 1));

//     let pickupTimeText = '';
//     if (orderTypes == 3) {
//         pickupTimeText = 'Shte%20vzeme%20v:%20' + pickupDateFormat + '%20' + pickupTime;
//     } else if (orderTypes == 2) {
//         pickupTimeText = 'Dostavi%20v:%20' + pickupDateFormat + '%20' + pickupTime;
//     }

//     let prods = '';
//     Object.values(products).map(p => {
//         prods += p.quantity + ' x ' + p.name + ' | ';
//     });

//     axios.get(`https://platform.clickatell.com/messages/http/send?apiKey=q8ppKOM_Sy-gydxpYqKXvw==&to=359884014689&content=TabL.BG%20|%20Nova%20poruchka%20ot%20${name}:%20${prods}%20${pickupTimeText}`) //DENIS PHONE

// }


const setOrderDate = (date, time) => {
    // Calculates year and returns the whole date mm.dd.yyyy hh:ii

    if (!(date && time && date.indexOf('.') != -1)) return '';
    if (time && time.indexOf(':') == -1) time = '';
    let splitedDate = date.split('.');
    let selectedOrderMonth = splitedDate[0];
    let selectedOrderDate = splitedDate[1];
    const now = new Date();
    if (selectedOrderMonth == 12) {
        if (now.getDate() > 20 && selectedOrderDate < 10) {
            return date + '.' + (parseInt((new Date()).getFullYear()) + 1) + ' ' + time;
        }
    }
    return date + '.' + (new Date()).getFullYear() + ' ' + time;
}

const sendRequestDelivery = (data, orderComment) => dispatch => {
    const { name, phone, address, addressDetails, email, orderDate, orderTime, regionId, deliveryRadiusId, deliveryShapeId, payment_method, payment_instrument, cutlery, promoCode, promoCodeApplied, orderTextComment } = data;

    const userToken = Auth.getUserToken();
    var to_order = getLocalCart();

    let productsArrToSend = [];
    Object.values(to_order).map(t =>
        productsArrToSend = productsArrToSend.concat(t)
    );

    axios.post(API_URL + 'orders/delivery?t=' + ((new Date()).getTime()), {
        products: productsArrToSend,
        client_name: name,
        client_phone: phone,
        client_address: address + (addressDetails && addressDetails.length > 0 ? " / " + addressDetails : ""),
        street_number: data.streetNumber ?? null,
        floor_number: data.floorNumber ?? null,
        ap_number: data.apNumber ?? null,
        bell_number: data.bellNumber ?? null,
        street_number: data.streetNumber ?? null,
        delivery_postal_code: data.postalCode ?? null,
        client_email: email,
        token: userToken,
        order_comment: orderComment,
        delivery_time: setOrderDate(orderDate, orderTime),
        exact_time_selected: orderTime && orderTime.indexOf(':') == -1 ? 0 : 1,
        regionId: regionId,
        deliveryRadiusId: deliveryRadiusId,
        deliveryShapeId: deliveryShapeId,
        payment_method: payment_method,
        cutlery: cutlery,
        cartId: getCartIdLocal(),
        promo_code: promoCodeApplied ? promoCode : '',
        order_text_comment: orderTextComment,
        subscribe_for_lunch_menu: data.subscribe_for_lunch_menu,
        lat: data.lat,
        lng: data.lng,
        free_product: data.free_product,
        count_cutlery: data.count_cutlery,
        userDeviceData: Utils.getDeviceAndBrowserDetails()
    }, { timeout: 55000 }) // Timeout 35 sec
        .then(res => {
            dispatch({
                type: ORDERED_SUCCESSFULLY,
                payload: { message: "Поръчката ви скоро ще бъде доставена.", token: res.data.token, current_order_type: 2, orderPaymentMethod: payment_method } //No translation needed
            })
            // Set token when delivery/preorder
            if (userToken == '' || !userToken) {
                Auth.setUserToken(res.data.token);
            }
            // Purchase pixel
            if (window.location.hostname !== 'localhost'
                && window.location.hostname !== 'test.tabl.bg'
                && window.location.hostname !== 'gabrovo-pizza.tabl.bg'
            ) {
                ReactPixel.trackSingleCustom('2912758035505954', "Purchase", { currency: "BGN", value: res.data.totalPrice.toFixed(2) }); // For tracking custom events.
            }

            dispatch(handleOnlinePayment(res.data.key, payment_method, payment_instrument, 'delivery', res.data));
            dispatch(getRegisteredUserDiscounts());

            dispatch({ type: LOADING_ORDER_END });

        }).catch(error => {
            dispatch(makeOrderCatcher(error));

            Auth.removeUserToken();
            dispatch({ type: LOADING_ORDER_END });
            // throw (error);
        });

}



const sendRequestPickup = (data, orderComment) => dispatch => {
    const { name, phone, address, email, orderDate, orderTime, payment_method, payment_instrument, cutlery, promoCode, promoCodeApplied, orderTextComment, count_cutlery } = data;
    const userToken = Auth.getUserToken();
    var to_order = getLocalCart();

    let productsArrToSend = [];
    Object.values(to_order).map(t =>
        productsArrToSend = productsArrToSend.concat(t)
    );
    axios.post(API_URL + 'orders/preorder', {
        products: productsArrToSend,
        client_name: name,
        client_phone: phone,
        // client_address: address, no need to send address on pickup order
        client_email: email,
        token: userToken,
        order_comment: orderComment,
        pickup_time: setOrderDate(orderDate, orderTime),
        exact_time_selected: orderTime && orderTime.indexOf(':') == -1 ? 0 : 1,
        payment_method: payment_method,
        cutlery: cutlery,
        promo_code: promoCodeApplied ? promoCode : '',
        order_text_comment: orderTextComment,
        subscribe_for_lunch_menu: data.subscribe_for_lunch_menu,
        free_product: data.free_product,
        pickupPlaceId: data.pickupPlaceId ? data.pickupPlaceId : null,
        count_cutlery: count_cutlery,
    }, { timeout: 55000 }) // Timeout 35 sec
        .then(res => {

            dispatch({
                type: ORDERED_SUCCESSFULLY,
                payload: { message: "Поръчката ви скоро ще бъде приготвена.", token: res.data.token, current_order_type: 3 } //No translation needed
            })

            // Set token when delivery/preorder
            if (userToken == '' || !userToken) {
                Auth.setUserToken(res.data.token);
            }

            // Purchase pixel
            if (window.location.hostname !== 'localhost'
                && window.location.hostname !== 'test.tabl.bg'
                && window.location.hostname !== 'gabrovo-pizza.tabl.bg'
            ) {
                ReactPixel.trackSingleCustom('2912758035505954', "Purchase", { currency: "BGN", value: res.data.totalPrice.toFixed(2) }); // For tracking custom events.
            }

            dispatch(handleOnlinePayment(res.data.key, payment_method, payment_instrument, 'pickup', res.data));

            dispatch(getRegisteredUserDiscounts());

            dispatch({ type: LOADING_ORDER_END });

        }).catch(error => {
            dispatch(makeOrderCatcher(error));
            Auth.removeUserToken();
            dispatch({ type: LOADING_ORDER_END });
            // throw (error);
        });

}

const sendRequestRoom = (data, orderComment) => dispatch => {
    const { name, phone, address, email, orderDate, orderTime, room_number, payment_method, payment_instrument, cutlery, promoCode, promoCodeApplied, additional_order_type, orderTextComment } = data;
    const userToken = Auth.getUserToken();
    var to_order = getLocalCart();

    let productsArrToSend = [];
    Object.values(to_order).map(t =>
        productsArrToSend = productsArrToSend.concat(t)
    );
    axios.post(API_URL + 'orders/room', {
        products: productsArrToSend,
        client_name: name,
        client_phone: phone,
        client_email: email,
        token: userToken,
        order_comment: orderComment,
        pickup_time: setOrderDate(orderDate, orderTime),
        exact_time_selected: orderTime && orderTime.indexOf(':') == -1 ? 0 : 1,
        room_number: Auth.getRoomNumInToken() ? Auth.getRoomNumInToken() : room_number ? room_number : '',
        payment_method: payment_method,
        cutlery: cutlery,
        cutlery: cutlery,
        promo_code: promoCodeApplied ? promoCode : '',
        additional_order_type: additional_order_type,
        order_text_comment: orderTextComment,
    }, { timeout: 55000 }) // Timeout 35 sec
        .then(res => {
            dispatch({
                type: ORDERED_SUCCESSFULLY,
                payload: { message: "Поръчката ви скоро ще бъде сервирана.", token: res.data.token, current_order_type: 6 } //No translation needed
            })

            // Set token when delivery/preorder
            if (userToken == '' || !userToken) {
                Auth.setUserToken(res.data.token);
            }

            // Purchase pixel
            if (window.location.hostname !== 'localhost'
                && window.location.hostname !== 'test.tabl.bg'
                && window.location.hostname !== 'gabrovo-pizza.tabl.bg'
            ) {
                ReactPixel.trackSingleCustom('2912758035505954', "Purchase", { currency: "BGN", value: res.data.totalPrice.toFixed(2) }); // For tracking custom events.
            }

            dispatch(handleOnlinePayment(res.data.key, payment_method, payment_instrument, 'room', res.data));

            dispatch(getRegisteredUserDiscounts());

            dispatch({ type: LOADING_ORDER_END });

        }).catch(error => {
            dispatch(makeOrderCatcher(error));

            Auth.removeUserToken();
            dispatch({ type: LOADING_ORDER_END });
            // throw (error);
        });

}

const sendRequestRestaurant = (orderComment, stranslations) => dispatch => {
    var to_order = getLocalCart();
    const userToken = Auth.getUserToken();

    // dispatch(setCurrOpenedModal( "multiple" ));
    let productsArrToSend = [];
    Object.values(to_order).map(t =>
        productsArrToSend = productsArrToSend.concat(t)
    );

    axios
        .post(API_URL + 'orders', {
            // table_token: userToken,
            session: userToken,
            products: productsArrToSend,
            order_comment: orderComment
        }, { timeout: 55000 }) // Timeout 35 sec
        .then(res => {

            // Purchase pixel
            if (window.location.hostname !== 'localhost'
                && window.location.hostname !== 'test.tabl.bg'
                && window.location.hostname !== 'gabrovo-pizza.tabl.bg'
            ) {
                ReactPixel.trackSingleCustom('2912758035505954', "Purchase", { currency: "BGN", value: res.data.totalPrice.toFixed(2) }); // For tracking custom events.
            }

            dispatch({
                type: ORDERED_SUCCESSFULLY,
                payload: { message: "Поръчката ви скоро ще бъде сервирана.", current_order_type: 1 } //No translation needed
            })

            let payment_method = res.data && res.data.paymentMethod || null;
            dispatch(handleOnlinePayment(null, payment_method, null, 'spot', res.data));


            dispatch(addSuccessMessage("Поръчката ви скоро ще бъде сервирана"))
            dispatch(clearCartLocal());
            dispatch(getRegisteredUserDiscounts());

            dispatch({ type: LOADING_ORDER_END });
            dispatch(closeScanModal());
            dispatch(setCurrOpenedModal(''))

        }).catch(error => {
            if (window.localStorage.getItem('app')) {
                // history.push("/close-browser-app")
                window.location.pathname = "/close-browser-app"
            } else {

                dispatch(openScanModal());

                let msg = "";
                if (Auth.getUserToken()) {
                    msg = "Моля сканирайте отново QR кода или такъв с активирана опция за поръчка";
                    dispatch(setOrderModalState(1));

                } else {
                    msg = "Възникна грешка при изпращане на поръчката"
                }

                // dispatch({
                //     type: ORDER_FAILED,
                //     payload: { message: msg } //No translation needed
                // });
                dispatch(makeOrderCatcher(error, msg));


                // dispatch(closeScanModal());

                // Auth.removeUserToken();
                dispatch({ type: LOADING_ORDER_END });
            }
        });
}

const sendRequestServedOnSpot = (data, orderComment) => dispatch => {
    const { name, phone, address, email, orderDate, orderTime, payment_method, payment_instrument, cutlery, promoCode, promoCodeApplied, orderTextComment } = data;
    const userToken = Auth.getUserToken();
    var to_order = getLocalCart();

    let productsArrToSend = [];
    Object.values(to_order).map(t =>
        productsArrToSend = productsArrToSend.concat(t)
    );
    axios.post(API_URL + 'orders/served-on-spot', {
        products: productsArrToSend,
        client_name: name,
        client_phone: phone,
        client_address: address,
        client_email: email,
        token: userToken,
        order_comment: orderComment,
        pickup_time: setOrderDate(orderDate, orderTime),
        exact_time_selected: orderTime && orderTime.indexOf(':') == -1 ? 0 : 1,
        payment_method: payment_method,
        cutlery: cutlery,
        promo_code: promoCodeApplied ? promoCode : '',
        order_text_comment: orderTextComment,
        subscribe_for_lunch_menu: data.subscribe_for_lunch_menu,
        free_product: data.free_product,
        pickupPlaceId: data.pickupPlaceId ? data.pickupPlaceId : null
    }, { timeout: 55000 }) // Timeout 35 sec
        .then(res => {

            dispatch({
                type: ORDERED_SUCCESSFULLY,
                payload: { message: "Поръчката ви скоро ще бъде приготвена.", token: res.data.token, current_order_type: 3 } //No translation needed
            })

            // Set token when delivery/preorder
            if (userToken == '' || !userToken) {
                Auth.setUserToken(res.data.token);
            }

            // Purchase pixel
            if (window.location.hostname !== 'localhost'
                && window.location.hostname !== 'test.tabl.bg'
                && window.location.hostname !== 'gabrovo-pizza.tabl.bg'
            ) {
                ReactPixel.trackSingleCustom('2912758035505954', "Purchase", { currency: "BGN", value: res.data.totalPrice.toFixed(2) }); // For tracking custom events.
            }

            dispatch(handleOnlinePayment(res.data.key, payment_method, payment_instrument, 'pickup', res.data));
            dispatch(getRegisteredUserDiscounts());

            dispatch({ type: LOADING_ORDER_END });

        }).catch(error => {
            dispatch(makeOrderCatcher(error));

            Auth.removeUserToken();
            dispatch({ type: LOADING_ORDER_END });
            // throw (error);
        });

}

const handleOnlinePayment = (orderKey, payment_method, payment_instrument = null, orderType = null, orderResp = null) => dispatch => {

    if(payment_method == 'online' && orderResp && orderResp.ppResp && orderResp.ppResp.redLink) {
        let slug = store.getState().client.slug
        history.push(`/${slug}/payment`)
        window.location = orderResp.ppResp.redLink;
    }
    else if (payment_method == 'epay') {
        dispatch(setCartModalOpenedState(false));
        dispatch(setProcessingPaymentModalOpened(true));

        if (payment_instrument) {
            dispatch(initiateEpayOrderOneTouch(orderKey, payment_instrument))
        } else {
            dispatch(initiateEpayOrder(orderKey))
        }
    } else {
        dispatch(clearCartLocal());

        let successMsg = 'Успешно направена поръчка';
        if (orderType == 'delivery') {
            successMsg = Utils.getSetting('seccess_message_delivery_order')
        } else if (orderType == 'pickup') {
            successMsg = Utils.getSetting('seccess_message_pickup_order')
        } else if (orderType == 'room') {
            successMsg = "Поръчката ви скоро ще бъде сервирана";
        }
        dispatch(addSuccessMessage(successMsg))
    }
}

export const clearOrderStatus = () => dispatch => {
    dispatch({ type: CLEAR_ORDER });
}

export const getAllProductsAccordingToPlaces = (showLoading = 0) => dispatch => {
    if (showLoading) {
        dispatch({ type: LOADING_START });
    }

    let url = 'productsAccordingToPlaces?active=1';
    // if (placeId !== 0) {
    //     url += '&place_id=' + placeId;
    // }

    axios.get(API_URL + url)
        .then(res => {
            dispatch({
                type: GET_ALL_PRODUCTS,
                payload: res.data
            });
            if (showLoading) {
                dispatch({ type: LOADING_END });
            }
        });
}

export const getAllProducts = (showLoading = 0 /*, placeId = 0*/) => dispatch => {
    if (showLoading) {
        dispatch({ type: LOADING_START });
    }

    let url = 'products?active=1';
    // if (placeId !== 0) {
    //     url += '&place_id=' + placeId;
    // }

    axios.get(API_URL + url)
        .then(res => {
            dispatch({
                type: GET_ALL_PRODUCTS,
                payload: res.data
            });
            if (showLoading) {
                dispatch({ type: LOADING_END });
            }
        });
}

export const getOrders = (uToken) => dispatch => {
    const userToken = uToken ? uToken : Auth.getUserToken();
    if (!userToken) {
        dispatch({
            type: GET_ORDERS,
            payload: []
        });
        return;
    }

    axios.get(API_URL + 'orders/by_table_session/' + userToken + `?timestamp=${new Date().getTime()}`)
        .then(result => {
            if (result.data.error) {
                dispatch({
                    type: GET_ORDERS,
                    payload: []
                })
            } else {
                dispatch({
                    type: GET_ORDERS,
                    payload: sortBy(result.data, ['id']).reverse()
                })
            }
        })
        .catch(err => {
            let errorData = err.response.data;
            if (errorData && errorData.error && errorData.error.errors && errorData.error.errors[0] && errorData.error.errors[0].message && errorData.error.errors[0].message == 'Invalid session') {
                Auth.removeUserToken();
                dispatch({
                    type: GET_ORDERS,
                    payload: []
                });
            }
        })
}

export const changeSelectedCartTab = (selectedCartTab) => dispatch => {
    dispatch({
        type: CHANGE_SELECTED_CART_TAB,
        payload: selectedCartTab
    });
}

export const removeUserTokenAndOrder = () => dispatch => {
    Auth.removeUserToken();
    dispatch({
        type: GET_ORDERS,
        payload: []
    });
}


export const clearBillWanted = () => dispatch => {
    dispatch({ type: CLEAR_BILL_ASKED, });
}

export const askForBill = (paymentMethod, placeId) => dispatch => {
    // console.log('placeId',placeId);
    const userToken = Auth.getUserToken();
    if (!userToken) {
        dispatch({
            type: BILL_ASKED,
            payload: false
            // payload: "Сметката ви е поискана успешно." //No translation needed
        });
        return;
    }

    dispatch({ type: LOADING_CART_START });

    axios
        .put(API_URL + `orders/by_table_session/${userToken}/bill_wanted`,
            {
                status: true,
                paymentMethod: paymentMethod,
                placeId: placeId || Auth.getOpenedPlace()
            }
        )
        .then(res => {
            dispatch({
                type: BILL_ASKED,
                payload: res.data
                // payload: "Сметката ви е поискана успешно." //No translation needed
            })
            dispatch(getOrders());

            // this.getOrders();
            dispatch({ type: LOADING_CART_END });
            Auth.updateUserTokenExpire(BILL_WANTED_EXPIRE_TIME_MINUTES);

            window.localStorage.setItem('bill_wanted', 1);
        })
        .catch(err => {
            dispatch({ type: LOADING_CART_END });
            dispatch({
                type: BILL_ASKED,
                payload: false
            })
        });
}



export const setProductPageId = (productId) => dispatch => {
    dispatch({
        type: SET_PRODUCT_PAGE_ID,
        payload: productId
    });
}

export const removeProductPageId = () => dispatch => {
    dispatch({
        type: REMOVE_PRODUCT_PAGE_ID
    });
}

export const setCommentModalData = (open, itemName, itemProductVariantId) => dispatch => {

    //     openCommentModal
    // commentModalTitle
    // commentVariantId

    dispatch({
        type: OPEN_COMMENT_MODAL,
        payload: open
    });
    dispatch({
        type: COMMENT_MODAL_TITLE,
        payload: itemName
    });
    dispatch({
        type: COMMENT_VARIANT_ID,
        payload: itemProductVariantId
    });
}


export const closePaymentTypeModal = () => dispatch => {
    dispatch({
        type: PAYMENT_TYPE_MODAL_OPENED,
        payload: false
    });
}

export const openPaymentTypeModal = () => dispatch => {
    dispatch({
        type: PAYMENT_TYPE_MODAL_OPENED,
        payload: true
    });
}

export const setDisabledMakeOrderButton = (status) => dispatch => {
    dispatch({
        type: SET_DISABLED_MAKE_ORDER_BUTTON,
        payload: status
    });
}